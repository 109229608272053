import React from "react"
import Img from "gatsby-image"
import marked from "marked"

import SEO from "../components/SEO"
// import specialties from "../config/specialtiesList"

export default function SpecialtiesPage({ data }) {
  const specialties = data.specialties.nodes
  return (
    <div>
      <SEO title="Specialties" />
      <section className="container my-5" id="specialties">
        <div className="row px-3 mb-3">
          <div className="col">
            <h1>Specialties</h1>
            <ul className="list-group">
              {specialties.map(specialty => (
                <li
                  key={specialty.name}
                  className="list-group-item"
                  style={{ padding: "0", border: "none" }}
                >
                  <div className="card specialty">
                    <div
                      style={{
                        height: "204px",
                        width: "204px",
                        flexShrink: "0",
                      }}
                    >
                      <Img
                        fluid={specialty.image.asset.fluid}
                        alt={specialty.name}
                      />
                    </div>

                    <div className="card-body">
                      <h5 className="card-title">{specialty.name}</h5>
                      <div
                        className="card-text"
                        dangerouslySetInnerHTML={{
                          __html: marked(specialty.description),
                        }}
                      />
                      <a
                        href="https://urbancarechiropractic.schedulista.com/"
                        target="_blank"
                        className="btn btn-primary"
                        style={{
                          marginLeft: "auto",
                          display: "block",
                          width: "140px",
                        }}
                      >
                        Appointment →
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}
export const query = graphql`
  query {
    specialties: allSanitySpecialties {
      nodes {
        id
        name
        description
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
